import {ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output} from "@angular/core";
import {CityService} from "@core/service/city";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
    selector: ".c-button-city-link, app-button-city-link",
    templateUrl: "./city-link.component.html",
    styleUrls: ["./city-link.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityLinkComponent implements OnInit {
    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
    public nameCity: Observable<string>;

    private city = inject(CityService);

    ngOnInit(): void {
        this.nameCity = this.city.city$.pipe(
            map((res) => {
                return res.name;
            }),
        );
    }
}
