import {Injectable} from "@angular/core";
import {Cookie} from "@core/service/cookie";
import {CookieHandler} from "@core/module/angular-ab-tests/classes";

@Injectable({
    providedIn: "root",
})
export class AbTestCookieService implements CookieHandler {
    constructor(private _cookie: Cookie) {}

    get(name: string): string {
        return this._cookie.getItem(name);
    }

    set(name: string, value: string, domain?: string, expires?: number) {
        this._cookie.setItem(name, value, domain, expires);
    }
}
