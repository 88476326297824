import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {REQUEST} from "@common/tokens/express.tokens";
import {RedirectInject} from "@core/service/redirect/inject";
import {RedirectBrowserService} from "@core/service/redirect/browser";
import {RedirectBrowserService as RedirectV2BrowserService} from "@common/core/service/redirect/redirect-browser.service";
import {CookieInject} from "@core/service/cookie/inject";
import {CookieBrowser} from "@core/service/cookie/browser";
import {ORIGIN_HEADERS, ORIGIN_HREF, ORIGIN_URL, ORIGIN_USER_AGENT} from "@shared/injection-token/tokens";
import {RedirectV2Inject} from "@common/core/service/redirect/inject";

export function getRequest(): any {
    return {headers: {cookie: document.cookie}};
}
/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const CONFIG_CORE_BROWSER = [
    {
        provide: Sentry.TraceService,
        deps: [Router],
    },
    {
        provide: REQUEST,
        useFactory: getRequest,
    },
    {provide: RedirectInject, useClass: RedirectBrowserService},
    {provide: RedirectV2Inject, useClass: RedirectV2BrowserService},
    {provide: CookieInject, useClass: CookieBrowser},
    {
        provide: ORIGIN_URL,
        useValue: location.origin,
    },
    {
        provide: ORIGIN_HEADERS,
        useValue: null,
    },
    {
        provide: ORIGIN_HREF,
        useValue: location.href,
    },
    {
        provide: ORIGIN_USER_AGENT,
        useValue: window.navigator.userAgent,
    },
];
