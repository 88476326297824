import {ChangeDetectionStrategy, Component, inject, OnInit} from "@angular/core";
import {MobileService} from "@core/service/mobile";
import {SmartBannerService} from "@common/shared/service/smart-banner/smart-banner.service";
import {Router} from "@angular/router";

@Component({
    selector: "app-ui-smart-banner",
    templateUrl: "./smart-banner.component.html",
    styleUrls: ["smart-banner.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSmartBannerComponent implements OnInit {
    private smartBannerService = inject(SmartBannerService);
    private mobileService = inject(MobileService);
    private router = inject(Router);
    public show: boolean = false;

    ngOnInit() {
        this.setBanner();
    }

    onClose() {
        this.smartBannerService.setStatus();
        this.show = false;
    }

    setBanner() {
        const url = this.router.url;
        if (url.indexOf("/product/") !== -1 && url.indexOf("/video") !== -1) {
            return;
        }
        const status = this.smartBannerService.getStatus();
        if (!status) {
            this.show = !this.mobileService.isApple;
        }
    }
}
