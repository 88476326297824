import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {CityService} from "@core/service/city";

/**
 * @deprecated use common/shared/pipe/format-html.ts
 */
@Pipe({
    name: "formatHtml",
})
export class FormatHtmlPipe implements PipeTransform {
    constructor(
        private sanitizer: DomSanitizer,
        private _cityService: CityService,
    ) {}
    transform(_html: string) {
        const city = this._cityService.data;
        if (_html) {
            return this.sanitizer.bypassSecurityTrustHtml(_html.replace(/(#city#)/gi, city.code));
        }
    }
}
