import {Directive, HostListener, inject, signal} from "@angular/core";
import {Dialog} from "@angular/cdk/dialog";
import {DIALOG_UI_CITY_SELECTION_CONFIG} from "@component/dialog/ui/city-selection/city-selection";

@Directive({
    selector: "[maDialogCitySelection]",
    standalone: true,
})
export class DialogCitySelectionDirective {
    public isLoading = signal(false);
    private dialog = inject(Dialog);

    @HostListener("click")
    onClickCity() {
        if (!this.isLoading()) {
            this.onLoadingStart();
            import("@shared/module_v2/dialog/ui/city-selection/city-selection.component")
                .then((res) => {
                    this.dialog.open(res.DialogCitySelectionComponent, DIALOG_UI_CITY_SELECTION_CONFIG);
                    this.isLoading.set(false);
                })
                .catch(() => {
                    this.isLoading.set(false);
                });
        }
    }

    onLoadingStart() {
        this.isLoading.set(true);
    }
}
