import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal} from "@angular/core";
import {CityService} from "@core/service/city";
import {Subscription} from "rxjs";

@Component({
    selector: "app-city",
    templateUrl: "./city.component.html",
    styleUrls: ["./city.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityComponent implements OnInit, OnDestroy {
    public count = signal(0);
    private cityService = inject(CityService);
    private city$ = new Subscription();

    ngOnInit(): void {
        this.city$ = this.cityService.city$.subscribe(() => {
            this.count.set(this.cityService.data.stores_quantity);
        });
    }

    ngOnDestroy() {
        this.city$.unsubscribe();
    }
}
