import {inject, Injectable} from "@angular/core";
import {CookieHandler} from "../classes";
import {CookieInject} from "@core/service/cookie/inject";

@Injectable({
    providedIn: "root",
})
export class ServerCookieHandlerService implements CookieHandler {
    private cookieService = inject(CookieInject);

    get(name: string): string {
        if (this.cookieService) {
            return this.cookieService.getItem(name);
        }
    }

    set(name: string, value: string): void {
        if (this.cookieService) {
            this.cookieService.setItem(name, value);
        }
    }
}
