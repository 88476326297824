import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FloatingBlockBottomComponent} from "@shared/module_v2/ui/floating-block-bottom/floating-block-bottom.component";
import {ButtonTalkMeComponent} from "@common/shared/component/button/talk-me/talk-me.component";
import {FooterMobileComponent} from "@shared/module_v2/layout/footer-mobile/footer-mobile.component";

@NgModule({
    declarations: [FloatingBlockBottomComponent],
    imports: [CommonModule, ButtonTalkMeComponent, FooterMobileComponent],
    exports: [FloatingBlockBottomComponent],
    providers: [],
})
export class UiFloatingBlockBottomModule {}
