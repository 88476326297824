import {CONFIG_CORE} from "@common/core/config/general";
import {APP_INITIALIZER_GENERAL} from "@common/core/app-initializer/general";
import {APP_ID, APP_INITIALIZER, importProvidersFrom, LOCALE_ID} from "@angular/core";
import {PopupYourCityService} from "@common/shared/service/popup-your-city/popup-your-city.service";
import {SmartBannerService} from "@common/shared/service/smart-banner/smart-banner.service";
import {RefererService} from "@common/shared/service/referer/referer.service";
import {provideClientHydration} from "@angular/platform-browser";
import localeRuAt from "@angular/common/locales/ru";
import {registerLocaleData} from "@angular/common";
import {routes} from "./app.routing";
import {provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withRouterConfig} from "@angular/router";
import {NgxPageScrollModule} from "ngx-page-scroll";
import {AbTestModule} from "@core/module/ab-test/ab-test.module";

registerLocaleData(localeRuAt);

/**
 * Общий конфиг для браузера и серверной части сайта
 */
export const CONFIG = [
    ...CONFIG_CORE,
    ...APP_INITIALIZER_GENERAL,
    provideClientHydration(),
    provideRouter(
        routes,
        withRouterConfig({
            onSameUrlNavigation: "reload",
            paramsInheritanceStrategy: "always",
        }),
        withEnabledBlockingInitialNavigation(),
        withInMemoryScrolling({
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
        }),
    ),

    importProvidersFrom(NgxPageScrollModule),
    {
        provide: APP_INITIALIZER,
        useFactory: (popupYourCityService: PopupYourCityService) => () => popupYourCityService.load(),
        deps: [PopupYourCityService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (smartBannerService: SmartBannerService) => () => smartBannerService.load(),
        deps: [SmartBannerService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (refererService: RefererService) => () => refererService.load(),
        deps: [RefererService],
        multi: true,
    },
    {provide: APP_ID, useValue: "ma"},

    {
        provide: LOCALE_ID,
        useValue: "ru-RU",
    },
    importProvidersFrom(AbTestModule),
];
