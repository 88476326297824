import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal, WritableSignal} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DIALOG_UI_SEARCH_CONFIG} from "@shared/module_v2/dialog/ui/search/search";
import {Dialog} from "@angular/cdk/dialog";

@Component({
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    public query: WritableSignal<string> = signal<string>("");
    private queryParamMap$: Subscription = new Subscription();
    private dialog = inject(Dialog);
    private _activatedRoute = inject(ActivatedRoute);
    private loadingDialog: boolean = false;

    ngOnInit() {
        this.initSubs();
    }

    ngOnDestroy() {
        this.queryParamMap$.unsubscribe();
    }

    initSubs() {
        this.queryParamMap$ = this._activatedRoute.queryParamMap.subscribe((res) => {
            this.query.set(res.has("q") ? res.get("q") : "");
        });
    }

    onFocusMobile() {
        this.openDialog(this.query() === "");
    }

    onClearValue() {
        this.openDialog(true);
        this.query.set("");
    }

    openDialog(clear: boolean = false) {
        if (!this.loadingDialog) {
            this.loadingDialog = true;
            import("@shared/module_v2/dialog/ui/search/search.component")
                .then((res) => {
                    const options = DIALOG_UI_SEARCH_CONFIG;
                    options.data.clear = clear;
                    //Попробовать, потом убрать
                    setTimeout(() => {
                        const dialogRef = this.dialog.open(res.UiDialogSearchComponent, options);
                        dialogRef.closed.subscribe((res: string) => {
                            this.query.set(res);
                        });
                    });
                })
                .finally(() => {
                    this.loadingDialog = false;
                });
        }
    }
}
