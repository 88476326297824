import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // const {url, method} = request;
        // if (url.endsWith("ma/site/v4/items/favorites/recommendations/") && method === "GET") {
        //     const obj = {
        //         "id": 121390,
        //     };
        //
        //     return of(new HttpResponse({
        //         status: 200, body: {
        //             list: Array(10).fill(obj),
        //         },
        //     })).pipe(delay(500));
        // }

        return next.handle(request);
    }
}
