import {ResolveFn} from "@angular/router";
import {delay, of} from "rxjs";
import {inject} from "@angular/core";
import {PlatformService} from "@common/core/service/platform";

/**
 * Временное решение для резолвера гео чтобы INP не падал
 */
export const geoResolver: ResolveFn<any> = () => {
    const platformService = inject(PlatformService);
    if (platformService.server) {
        return of(true).pipe(delay(0));
    }
    return of(true).pipe(delay(100));
};
