<div class="box" maDialogCitySelection>
    <div class="city">
        <app-button-city-link />
        <div class="icon ma-svg-arrow-down"></div>
    </div>
    @if (count()) {
        <div class="count">{{ count() }} {{ count() | numEnding: ["аптека", "аптеки", "аптек"] }}</div>
    }
</div>
<ma-ui-popup-your-city />
