import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit} from "@angular/core";
import {TalkMeService} from "@shared/service/talk-me/talk-me";
import {Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {ChatOperatorAvailableService} from "@shared/service/api/chat/operator-available";

@Component({
    standalone: true,
    selector: "ma-button-talk-me",
    templateUrl: "./talk-me.component.html",
    styleUrls: ["./talk-me.component.less"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ChatOperatorAvailableService],
})
export class ButtonTalkMeComponent implements OnInit, OnDestroy {
    public isButton: boolean = false;
    public isOperator: boolean = false;

    private isForbidden: boolean = false;
    private isFullyLoaded: boolean = false;
    private listForbidden: string[] = ["specials", "/basket/order", "/basket/on-order"];
    private fullyLoaded$: Subscription;
    private statusChat$: Subscription;
    private events$: Subscription;

    constructor(
        private _talkMeService: TalkMeService,
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router,
        private _elementRef: ElementRef,
        private _chatOperatorAvailableService: ChatOperatorAvailableService,
        private _cdr: ChangeDetectorRef,
    ) {
        this.events$ = this._router.events.pipe(filter((res) => res instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
            if (this.isFullyLoaded) {
                this.isButton = true;
            }
            this.isForbidden = false;

            this.listForbidden.map((v) => {
                if (res.url.indexOf(v) !== -1) {
                    this.isForbidden = true;
                    this.isButton = false;
                }
            });

            this._cdr.detectChanges();
        });
    }

    ngOnInit(): void {
        this.fullyLoaded$ = this._talkMeService.fullyLoaded$.subscribe((res) => {
            if (!this.isForbidden) {
                this.isButton = res;
            }

            this.isFullyLoaded = res;
            this._cdr.detectChanges();
        });

        this.statusChat$ = this._talkMeService.statusChat$.pipe(filter(() => !this.isForbidden)).subscribe((res) => {
            this.isButton = res === "close";
            this._cdr.detectChanges();
        });

        this._chatOperatorAvailableService.get().subscribe((res) => {
            this.isOperator = res;
            this._cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this.fullyLoaded$) {
            this.fullyLoaded$.unsubscribe();
        }
        if (this.statusChat$) {
            this.statusChat$.unsubscribe();
        }
        if (this.events$) {
            this.events$.unsubscribe();
        }
    }

    onClick() {
        this.setPosition();
        this._talkMeService.openChat();
    }

    setPosition() {
        const windowHeight = window.innerHeight;
        const elem: HTMLElement = this._elementRef.nativeElement;
        const elemBoundingClientRect = elem.getBoundingClientRect();
        const posY = windowHeight - elemBoundingClientRect.top;
        this._talkMeService.setPosition(-50, posY * -1);
    }
}
