import {ErrorHandler} from "@angular/core";
import {ErrorHandlerService} from "@common/core/handler/error";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideToastr} from "ngx-toastr";
import {HttpCancelRepeatInterceptor} from "@core/interceptor/http-cancel-repeat-interceptor.interceptor";
import {HttpInterceptor} from "@core/interceptor/http.interceptor";
import {HttpMockInterceptor} from "@core/interceptor/http-mock.interceptor";
import {HttpErrorInterceptor} from "@core/interceptor/http-error.interceptor";

/**
 * Только для общих сервисов которые нужно загрузить при инициализации приложения.
 */
export const CONFIG_CORE = [
    provideToastr({
        maxOpened: 5,
    }),
    {
        provide: ErrorHandler,
        useClass: ErrorHandlerService,
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpCancelRepeatInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpMockInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true,
    },
];
